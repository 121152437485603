import React, { useId } from "react"
import { Controller, useFormContext } from "react-hook-form"
import CustomSelect from "@components/elements/CustomSelect/CustomSelect"
import InputBox from "./InputBox"

const Select = ({ name, label, defaultValue, options }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const uniqueID = useId()

  return (
    <InputBox name={name} label={label} errors={errors}>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, value, filedName } }) => (
          <div className="input-field input-select">
            <CustomSelect
              instanceId={uniqueID}
              options={options}
              value={options.find((option) => option.value === value)}
              onChange={(option) => onChange(option?.value)}
              name={filedName}
            />
          </div>
        )}
      />
    </InputBox>
  )
}

export default Select
