import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const BannerBG = () => {
  return (
    <div className="bg-section-gradient">
      {/* <StaticImage
        src="../../images/rf.webp"
        alt="gradient-bg"
        className="bg-img"
        loading="eager"
      /> */}
      {/* <div className="overlay" /> */}
    </div>
  )
}

export default BannerBG
