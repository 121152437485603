import clsx from "clsx"
import { isEmpty } from "lodash"
import React, { useEffect } from "react"
import ReactSlider from "react-slick"
import "./Slider.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { ChevronLeft, ChevronRight } from "lucide-react"
import RightIcon from "@components/icons/ChevronRight"
import LeftIcon from "@components/icons/ChevronLeft"

const SIZES = {
  sm: {
    mobile: 1.1,
    tablet: 2,
    laptop: 3,
    desktop: 3,
  },
  md: {
    mobile: 1.1,
    tablet: 2,
    laptop: 3,
    desktop: 4,
  },
  lg: {
    mobile: 1.1,
    tablet: 2,
    laptop: 4,
    desktop: 4,
  },
  lg2: {
    mobile: 2,
    tablet: 2,
    laptop: 4,
    desktop: 4,
  },
  "lg-alt": {
    mobile: 2,
    tablet: 3,
    laptop: 5,
    desktop: 5,
  },
  xl: {
    mobile: 2,
    tablet: 4,
    laptop: 5,
    desktop: 6,
  },
  "xl-alt": {
    mobile: 2,
    tablet: 3,
    laptop: 5,
    desktop: 6,
  },
}

function NextArrow(props) {
  const { className, onClick } = props
  return (
    <div
      className={clsx(className, "slick-next", "custom-arrow")}
      onClick={onClick}
    >
      <RightIcon />
    </div>
  )
}
function PrevArrow(props) {
  const { className, onClick } = props
  return (
    <div
      className={clsx(className, "slick-prev", "custom-arrow")}
      onClick={onClick}
    >
      <LeftIcon />
    </div>
  )
}

const Slider = React.forwardRef(
  (
    {
      children,
      className,
      size,
      darkMode,
      customSettings,
      infinite,
      draggable = true,
      noArrows,
      autoplay,
    },
    ref
  ) => {
    const variant = isEmpty(size) ? SIZES.md : SIZES[size]

    const options = {
      dots: false,
      arrows: false,
      infinite,
      slidesToShow: variant.desktop,
      slidesToScroll: 1,
      centerMode: false,
      draggable,
      speed: autoplay ? 3000 : 500,
      autoplay,
      autoplaySpeed: 3000,
      cssEase: autoplay ? "linear" : "ease-in-out",
      nextArrow: !noArrows && <NextArrow />,
      prevArrow: !noArrows && <PrevArrow />,
      responsive: [
        {
          breakpoint: 3000,
          settings: {
            slidesToShow: variant.desktop,
            slidesToScroll: 1,
            infinite,
            dots: false,
            arrows: true,
          },
        },
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: variant.laptop,
            slidesToScroll: 1,
            infinite,
            dots: false,
            arrows: true,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: variant.tablet,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
            infinite,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: variant.mobile,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
            infinite,
          },
        },
      ],
    }

    return (
      <ReactSlider
        ref={ref}
        className={clsx(
          "custom-slider",
          { "custom-slider-dark": darkMode },
          className
        )}
        {...options}
        {...customSettings}
        id="custom-slider"
      >
        {children}
      </ReactSlider>
    )
  }
)

export const ImageSlider = React.forwardRef(({ children, oneImage, propertyCard }, ref) => {
  const options = {
    dots: oneImage ? false : propertyCard ? false: true,
    arrows: true,
    speed: 500,
    slidesToShow: propertyCard ? 1 : 2,
    slidesToScroll: 1,
    centerMode: false,
    infinite: propertyCard ? true : false, 
    lazyLoad: propertyCard ? 'progress' : "",
    nextArrow: (
      <div id="image-slider-next">
        <ChevronRight size={24} />
      </div>
    ),
    prevArrow: (
      <div id="image-slider-prev">
        <ChevronLeft size={24} />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: propertyCard ? 1 : oneImage ? 1 : 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  useEffect(() => {
    const slickSlider = document.querySelector(".image-slider")
    const slickList = slickSlider?.querySelector(".slick-list")
    const prevArrow = document.querySelector("#image-slider-prev")
    const nextArrow = document.querySelector("#image-slider-next")

    const slickSliderHeight = slickSlider?.clientHeight
    const slickListHeight = slickList?.clientHeight

    const offsetHeight = Math.floor((slickSliderHeight - slickListHeight) / 2)

    if (prevArrow) {
      prevArrow.style.height = `${slickListHeight}px`
      prevArrow.style.top = `calc(50% - ${offsetHeight}px)`
    }

    if (nextArrow) {
      nextArrow.style.height = `${slickListHeight}px`
      nextArrow.style.top = `calc(50% - ${offsetHeight}px)`
    }
  }, [])

  return (
    <Slider
      ref={ref}
      className="image-slider"
      size="lg"
      customSettings={options}
    >
      {children}
    </Slider>
  )
})

export default Slider
