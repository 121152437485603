/* eslint-disable no-unsafe-optional-chaining */
import { parseHTML } from "@lib/utils"
import clsx from "clsx"
import $ from "jquery"
import React, { useEffect, useRef, useState } from "react"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import "./ReadMore.scss"

const ReadMore = ({
  content,
  height,
  className,
  showMoreText,
  showLessText,
  scrollToId,
  isProperty
}) => {
  const [showReadMore, setShowReadMore] = useState(false)
  const [readMore, setReadMore] = useState(true)
  const maxHeight = height || 260

  const { isMobile, isTablet } = useDeviceMedia()

  const elementRef = useRef()

  useEffect(() => {
    if (elementRef?.current?.clientHeight > maxHeight) {
      setShowReadMore(true)
    }
    if (!elementRef.current) return
    if (readMore) {
      elementRef.current.style.maxHeight = `${maxHeight}px`
    } else {
      elementRef.current.style.maxHeight = ""
    }
  }, [readMore, maxHeight])
  const path = typeof window !== "undefined" && window.location.pathname
  useEffect(() => {
    // setShowReadMore(false)
    setReadMore(true)
  }, [isProperty, path])

  const handleClick = () => {
    if (!readMore) {
      let offSet
      if (isMobile) {
        offSet = 90
      } else if (isTablet) {
        offSet = 130
      } else {
        offSet = 160
      }
      $("html, body").animate({
        // eslint-disable-next-line no-unsafe-optional-chaining
        scrollTop:
          $(`#${scrollToId || "contentsection"}`)?.offset()?.top - offSet,
      })
    }
  }

  if (!content) return null

  return (
    <div className={clsx("read-more-wrap", className)}>
      <div
        ref={elementRef}
        className={clsx({
          "read-more-section": showReadMore,
          "read-more": readMore,
        })}
      >
        {parseHTML(content)}
      </div>
      {showReadMore && (
        <button
          onClick={() => {
            setReadMore((val) => !val)
            handleClick()
          }}
          className={`read-more-button ${readMore ? "" : "read-less"}`}
        >
          {readMore ? showMoreText || "Read More" : showLessText || "Read less"}
        </button>
      )}
    </div>
  )
}

export default ReadMore
