import React from "react"
// import ScrollAnimation from "react-animate-on-scroll"
// import "animate.css/animate.min.css"

const Animation = ({
  children,
  // animateInType,
  // noAnimation,
  wrapperClassName,
  // delay,
  ...props
}) => {
  // let animateIn = ""

  // switch (animateInType) {
  //   case "left":
  //     animateIn = "animate__fadeInLeft"
  //     break
  //   case "right":
  //     animateIn = "animate__fadeInRight"
  //     break
  //   case "up":
  //     animateIn = "animate__fadeInUp"
  //     break
  //   case "down":
  //     animateIn = "animate__fadeInDown"
  //     break
  //   default:
  //     animateIn = "animate__fadeIn"
  //     break
  // }

  // if (noAnimation)
  return (
    <div className={wrapperClassName}>
      <div {...props}>{children}</div>
    </div>
  )

  // return (
  //   <ScrollAnimation
  //     animateIn={animateIn}
  //     animateOnce
  //     className={wrapperClassName}
  //     delay={delay}
  //   >
  //     <div {...props}>{children}</div>
  //   </ScrollAnimation>
  // )
}

export default Animation
