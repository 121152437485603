import { privacyPolicyURL, termsAndConditionsURL } from "@lib/urls"
import { Link } from "gatsby"
import React from "react"

const TermsConditions = () => {
  return (
    <p className="form-terms-text">
      By clicking Submit, you agree to our{" "}
      <Link to={termsAndConditionsURL}>Terms & Conditions</Link> and{" "}
      <Link to={privacyPolicyURL}>Privacy Policy</Link>.
    </p>
  )
}

export default TermsConditions
