import ReadMore from "@components/ReadMore/ReadMore"
import React from "react"
import { Container } from "react-bootstrap"
import "./TextCopy.scss"
import clsx from "clsx"

const TextCopy = ({ content, title, className, isProperty }) => {
  return (
    <div
      className={clsx("text-copy-wrap section-p", className)}
      id="contentsection-text-copy"
    >
      <Container className="text-copy-container">
        {title && <h2 className="title">{title}</h2>}
        {content && (
          <ReadMore
            content={content}
            height={284}
            className="description"
            scrollToId="contentsection-text-copy"
            isProperty={isProperty}
          />
        )}
      </Container>
    </div>
  )
}

export default TextCopy
